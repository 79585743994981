
.wrapper {
    margin: 2rem;
    display: grid;
    gap: 4rem;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 25rem));
}

.card {
    background-color: #F6F6F6 !important;
    box-shadow: 0 0.1em 0.2em 0 rgba(0, 0, 0, 0.25), 0 0.1em 0.6em 0 rgba(0, 0, 0, 0.15) !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease;
    border: none;
    z-index: 1;
    position: relative;
    top: 0;
    text-align: left;
}

.card:hover {
    /* transform: scale(1.02) */
    box-shadow: 0 0.25em 0.5em 0.3rem rgb(233, 233, 184), 0 0.4em 1.25em 1rem rgba(0, 0, 0, 0.05) !important;
    top: -0.3rem;
    transform: scaleZ(5px) scale(1.02);
}

.card__img {
    width: 100%;
    object-fit: cover;
    background-size: 105% auto;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.6s ease;
    height: 11.8rem;
}

.card:hover .card__img {
    background-size: 100% auto;
}

.card__body {
    margin: 2rem 1rem 2rem 1rem;
    flex-grow: 1;
    height: fit-content;
}


.card__title {
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
    text-align: center;
}

.card__description {
    line-height: 1.5rem;
    padding-bottom: 5px;
}

@media screen and (max-width: 499px) {
    .card__img {
        height: 9rem;
    }
}


.card__line {
    border-top:1px dotted #000;
    background:#263238;
    width: 90%;
    margin: auto;
    position: absolute;
    bottom: 2.7rem;
}

.card__btn {
    transition: 0.2s;
    color: #263238;
    padding-right: 1rem;
}

.card__btn:hover {
    color: #276678;
    transform: scale(1.2)
}

.card__footer {
    display: inline;
    padding-right: 20px;
    padding-left: 0;
    list-style: "-";
    justify-content: space-between;
    position: absolute;
    bottom: 0;
}

.card__footer li {
    display: inline-flex;
}




