.skill-icon {
    bottom: 0 !important;
    color: #f5f5f5;
    list-style-type: none;
    horiz-align: center;
    vertical-align: center;
    align-items: center;
    display: inline-block;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem;
    text-align: center;
    background-color: #263238;
    margin: 2.5rem;
    border-radius: 25px;
}
.skill-icons :hover{
    transform: translateZ(5px) scale(1.02);
    cursor: pointer;
}

.sec-skill-icon {
    font-size: 1em;
    text-align: center;
}

.skills-div {
    align-items: center;
    text-align: center;
}


.main-skills-h1,
.complementary-skill-h1 {
    width: 100%;
    overflow: hidden;
    vertical-align:middle;
    color: #f5f5f5;
    background-color: #66806A;
    padding: 5px;
}

.skill-icons {
    padding: 10px 0 10px 0;
}

.skill-icon-text {
    margin: 0;
    margin-top: 0.6rem;;
}
