/* HomePage.css */
.homepage-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 120px); /* Adjust the 120px based on your navbar and footer height */
    padding: 1rem;
    gap: 20px; /* Space between text and image */
  }
  
  .text-content {
    flex: 1;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    animation: fadeIn 2s ease-out forwards;
  }
  
  .text-content h1, .text-content p {
    margin-bottom: 1rem;
  }
  
  .image-content {
    flex: 1;
    display: flex;
    justify-content: center;
    animation: scaleIn 1s ease-out forwards;
  }
  
  .image-content img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0,0,0,0.2);
  }
  
  @media screen and (max-width: 768px) {
    .homepage-container {
      flex-direction: column;
      min-height: auto;
    }
    .image-content img {
      max-width: 80%;
    }
  }
  /* Adjusting slideInLeft to include fade-in */
@keyframes slideInLeft {
    from { transform: translateX(-100px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  /* Adjusting scaleIn to include fade-in */
  @keyframes scaleIn {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  .homepage-container {
    animation: fadeInEffect 2s ease-out forwards;
    /* Remainder of your CSS */
  }
  @keyframes fadeInEffect {
    from { opacity: 0; }
    to { opacity: 1; }
  }
      