.home-header h1 {
    color: #f5f5f5;
    font-size: 3em;
}

.main-name {
    color: #cbba3b;
}

.Typewriter__wrapper {
    font-size: 2.8em;
    color: #ddd79c;
    font-weight: 600;

}
.Typewriter__cursor {
    font-size: 2.4em;
    color: #f5f5f5;
}

.about {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-card {
    margin-left: auto;
    margin-right: auto;
}

.card-header .card-body {
    margin: auto;
    display: flex;
    justify-content: center;

}