@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@250;300&display=swap);
.container {
    position: relative;
    margin-left: 0;
    padding-left: 0;
    max-width: 10px;
}

.navbar {
    background-color: #263238;
    text-align: left;
    width: 100%;
    height:100%;
    position: -webkit-sticky;
    position: sticky;
    padding: 0 !important;
    margin-left: 6px;
    margin-top: 2px;
    margin-right: 2px;
    border-radius: 10px;
}

.navbar-nav .nav-link {
    color: #f5f5f5 !important;
    padding: 1rem 1rem !important;
}

.navbar-nav .nav-item {
    position: relative;
    margin-right: 20px;
}

.navbar-nav .nav-item a:hover {
    background-color: #276678;
}

.toggle-icon {
    color: #f5f5f5 !important;
}

.navbar-toggler {
    padding: 0.5rem 1.5rem !important;
    position: relative !important;
    background-color: transparent !important;
    border: none transparent !important;
    outline: 0 !important;
    font-size: 2rem !important;
}
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
    box-shadow: none;
}

span {
    margin-left: 1rem;
}

/**
 * dark-mode styles 
 * 
 */

.darkmode>h2 {
    color: #3b3b3b;
    font-size: 24px;
    line-height: 33px;
    text-align: left;
    font-family: Source Sans Pro, Georgia, sans-serif;
    font-weight: 700;
    display: inline-block;
    vertical-align: top;
}

.darkmode-wrapper {
    width: 50%;
    margin: auto;
    padding: 10%;
    margin-top: 17px;
}

.dark-checkbox {
    opacity: 0;
    position: absolute;
}

.dark-label {
    width: 45px;
    height: 25px;
    background-color: #111;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
    border: 1px solid #66806a;
}

.dark-mode-ball {
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
    border: 1px solid #66806a;
}

/*  target the elemenent after the label*/
.dark-checkbox:checked+.dark-label .dark-mode-ball {
    transform: translateX(24px);
}

.darkmode-moon {
    color: pink;
}

.darkmode-sun {
    color: yellow;
}
.tags {
    margin: 0.5rem 0 0.5rem 0;
}

.tag {
    font-size: 11px !important;
    text-align: left !important;
    padding: 5px 8px 5px 8px !important;
    vertical-align: baseline;
    font-weight: lighter !important;
    margin: 0 6px 6px 2px;
    letter-spacing: 1px;
}


.title {
    font-size: 15em;
    text-align: center;
}


.vertical-timeline-element-title {
    text-align: center;
    margin-bottom: 0.7rem;
    font-size: 30px !important;
    color: blueviolet;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
    background: #F6F6F6;
}


.vertical-timeline-element-description{
    margin-bottom: 1rem;
    font-size: 18px !important;
}

.vertical-timeline-element-icon {
    background: #263238;
    color: #fff;
}

.vertical-timeline-element-content-arrow {
    border-right: 7px solid  #F6F6F6;
    border-right: 7px solid  #F6F6F6;
}

.vertical-timeline-element-date {
    color: rgb(0, 0, 0);
    margin: 0 20px;
    font-weight:bolder !important;
    font-size: large;
}

.home-header h1 {
    color: #f5f5f5;
    font-size: 3em;
}

.main-name {
    color: #cbba3b;
}

.Typewriter__wrapper {
    font-size: 2.8em;
    color: #ddd79c;
    font-weight: 600;

}
.Typewriter__cursor {
    font-size: 2.4em;
    color: #f5f5f5;
}

.about {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-card {
    margin-left: auto;
    margin-right: auto;
}

.card-header .card-body {
    margin: auto;
    display: flex;
    justify-content: center;

}

.wrapper {
    margin: 2rem;
    display: grid;
    grid-gap: 4rem;
    gap: 4rem;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 25rem));
}

.card {
    background-color: #F6F6F6 !important;
    box-shadow: 0 0.1em 0.2em 0 rgba(0, 0, 0, 0.25), 0 0.1em 0.6em 0 rgba(0, 0, 0, 0.15) !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease;
    border: none;
    z-index: 1;
    position: relative;
    top: 0;
    text-align: left;
}

.card:hover {
    /* transform: scale(1.02) */
    box-shadow: 0 0.25em 0.5em 0.3rem rgb(233, 233, 184), 0 0.4em 1.25em 1rem rgba(0, 0, 0, 0.05) !important;
    top: -0.3rem;
    transform: scaleZ(5px) scale(1.02);
}

.card__img {
    width: 100%;
    object-fit: cover;
    background-size: 105% auto;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.6s ease;
    height: 11.8rem;
}

.card:hover .card__img {
    background-size: 100% auto;
}

.card__body {
    margin: 2rem 1rem 2rem 1rem;
    flex-grow: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.card__title {
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
    text-align: center;
}

.card__description {
    line-height: 1.5rem;
    padding-bottom: 5px;
}

@media screen and (max-width: 499px) {
    .card__img {
        height: 9rem;
    }
}


.card__line {
    border-top:1px dotted #000;
    background:#263238;
    width: 90%;
    margin: auto;
    position: absolute;
    bottom: 2.7rem;
}

.card__btn {
    transition: 0.2s;
    color: #263238;
    padding-right: 1rem;
}

.card__btn:hover {
    color: #276678;
    transform: scale(1.2)
}

.card__footer {
    display: inline;
    padding-right: 20px;
    padding-left: 0;
    list-style: "-";
    justify-content: space-between;
    position: absolute;
    bottom: 0;
}

.card__footer li {
    display: inline-flex;
}





.skill-icon {
    bottom: 0 !important;
    color: #f5f5f5;
    list-style-type: none;
    horiz-align: center;
    vertical-align: center;
    align-items: center;
    display: inline-block;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem;
    text-align: center;
    background-color: #263238;
    margin: 2.5rem;
    border-radius: 25px;
}
.skill-icons :hover{
    transform: translateZ(5px) scale(1.02);
    cursor: pointer;
}

.sec-skill-icon {
    font-size: 1em;
    text-align: center;
}

.skills-div {
    align-items: center;
    text-align: center;
}


.main-skills-h1,
.complementary-skill-h1 {
    width: 100%;
    overflow: hidden;
    vertical-align:middle;
    color: #f5f5f5;
    background-color: #66806A;
    padding: 5px;
}

.skill-icons {
    padding: 10px 0 10px 0;
}

.skill-icon-text {
    margin: 0;
    margin-top: 0.6rem;;
}

/* Chatbot.css */
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 60vh; /* Adjusted from fixed px to vh for relative height */
  width: 90%; /* Adjusted for relative width */
  max-width: 700px; /* Ensures the chatbox doesn't get too wide on larger screens */
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 1rem;
  margin: 5vh auto; /* Adjusted to use vh for a more responsive vertical spacing */
}

.placeholder-questions {
  color: #999;
  font-style: italic;
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 5px;
  max-width: 80%;
  align-self: flex-start;
  transition: opacity 0.5s ease-in-out;
}

.messages-container.active-placeholder .placeholder-questions {
  opacity: 0;
  transition-delay: 0.5s;
}

.messages-container {
  height: 90%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.user-message,
.bot-message {
  margin: 5px;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  max-width: 80%;
}

.user-message {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

.bot-message {
  align-self: flex-start;
  background-color: #e9e9eb;
  color: black;
}

form {
  display: flex;
  width: 100%;
  align-items: center;
}

input {
  flex: 1 1;
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 8px;
  outline: none;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}



.footer {
    background-color: #263238;
    width: 100%;
    position: absolute;
    bottom: 0 !important;
    justify-content: space-between;
    margin-top: 30px;
}

.footer-body {
    text-align: center !important;
    bottom: 0;
    overflow: hidden;
    position: relative;
    color: #f5f5f5;
}

.social-icons {
    display: flex;
    color: #f5f5f5;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    max-width: 400px;
}

.social-icon {
    padding: 1rem;
    transition: background-color 0.1s ease;
}

.social-icon:hover {
    background-color: #276678;
}

@media screen and (max-width: 499px) {
    .social-icons svg {
        height: 30px;
    }
}
.App {
  position: relative;
  min-height: 100vh;
  padding-bottom: 6rem;
}
/* HomePage.css */
.homepage-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 120px); /* Adjust the 120px based on your navbar and footer height */
    padding: 1rem;
    grid-gap: 20px;
    gap: 20px; /* Space between text and image */
  }
  
  .text-content {
    flex: 1 1;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    animation: fadeIn 2s ease-out forwards;
  }
  
  .text-content h1, .text-content p {
    margin-bottom: 1rem;
  }
  
  .image-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    animation: scaleIn 1s ease-out forwards;
  }
  
  .image-content img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0,0,0,0.2);
  }
  
  @media screen and (max-width: 768px) {
    .homepage-container {
      flex-direction: column;
      min-height: auto;
    }
    .image-content img {
      max-width: 80%;
    }
  }
  /* Adjusting slideInLeft to include fade-in */
@keyframes slideInLeft {
    from { transform: translateX(-100px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  /* Adjusting scaleIn to include fade-in */
  @keyframes scaleIn {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  .homepage-container {
    animation: fadeInEffect 2s ease-out forwards;
    /* Remainder of your CSS */
  }
  @keyframes fadeInEffect {
    from { opacity: 0; }
    to { opacity: 1; }
  }
      
/* Header */
.large-header {
    position: relative;
    width: 100%;
    background: #333;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }
  
  #large-header {
    background-image: url('https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/img/demo-1-bg.jpg');
  }
  
  .main-title {
    position: absolute;
    margin: 0;
    padding: 0;
    color: #f9f1e9;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  
  .demo-1 .main-title {
    text-transform: uppercase;
    font-size: 4.2em;
    letter-spacing: 0.1em;
  }
  
  .main-title .thin {
    font-weight: 200;
  }
  
  @media only screen and (max-width: 768px) {
    .demo-1 .main-title {
      font-size: 3em;
    }
  }
  
body {
    margin: 0;
    font-family: "Raleway", serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url(/static/media/background_image.36b88043.jpeg);;
    background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.about-card {
    max-width: 1200px;
    width: 90%;
}

@media screen and (max-width: 499px) {
    html {
        font-size: 0.8em;
    }

    .heading {
        padding: 2rem 0;
        text-align: center;
        padding-bottom: 1rem;
    }

    .Typewriter {
        font-size: 0.6rem;
        margin-bottom: 2rem;
    }
}

.large-header {
    position: relative;
    width: 100%;
    background: #333;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    height: 100vh; /* Adjust this based on your need */
  }
  
  .large-header {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust this based on your need */
    background: #333 url('https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/img/demo-1-bg.jpg') no-repeat center center / cover;
    overflow: hidden;
    z-index: 1;
}
  
  .main-title {
    position: absolute;
    margin: 0;
    padding: 0;
    color: #f9f1e9;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    text-transform: uppercase;
    font-size: 4.2em;
    letter-spacing: 0.1em;
  }
  
  .main-title .thin {
    font-weight: 200;
  }
  
  @media only screen and (max-width: 768px) {
    .main-title {
      font-size: 3em;
    }
  }
  
