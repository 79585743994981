/* Chatbot.css */
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 60vh; /* Adjusted from fixed px to vh for relative height */
  width: 90%; /* Adjusted for relative width */
  max-width: 700px; /* Ensures the chatbox doesn't get too wide on larger screens */
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 1rem;
  margin: 5vh auto; /* Adjusted to use vh for a more responsive vertical spacing */
}

.placeholder-questions {
  color: #999;
  font-style: italic;
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 5px;
  max-width: 80%;
  align-self: flex-start;
  transition: opacity 0.5s ease-in-out;
}

.messages-container.active-placeholder .placeholder-questions {
  opacity: 0;
  transition-delay: 0.5s;
}

.messages-container {
  height: 90%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.user-message,
.bot-message {
  margin: 5px;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  max-width: 80%;
}

.user-message {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

.bot-message {
  align-self: flex-start;
  background-color: #e9e9eb;
  color: black;
}

form {
  display: flex;
  width: 100%;
  align-items: center;
}

input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 8px;
  outline: none;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


