.footer {
    background-color: #263238;
    width: 100%;
    position: absolute;
    bottom: 0 !important;
    justify-content: space-between;
    margin-top: 30px;
}

.footer-body {
    text-align: center !important;
    bottom: 0;
    overflow: hidden;
    position: relative;
    color: #f5f5f5;
}

.social-icons {
    display: flex;
    color: #f5f5f5;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    max-width: 400px;
}

.social-icon {
    padding: 1rem;
    transition: background-color 0.1s ease;
}

.social-icon:hover {
    background-color: #276678;
}

@media screen and (max-width: 499px) {
    .social-icons svg {
        height: 30px;
    }
}