@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@250;300&display=swap');
body {
    margin: 0;
    font-family: "Raleway", serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('./background_image.jpeg');;
    background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.about-card {
    max-width: 1200px;
    width: 90%;
}

@media screen and (max-width: 499px) {
    html {
        font-size: 0.8em;
    }

    .heading {
        padding: 2rem 0;
        text-align: center;
        padding-bottom: 1rem;
    }

    .Typewriter {
        font-size: 0.6rem;
        margin-bottom: 2rem;
    }
}

.large-header {
    position: relative;
    width: 100%;
    background: #333;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    height: 100vh; /* Adjust this based on your need */
  }
  
  .large-header {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust this based on your need */
    background: #333 url('https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/img/demo-1-bg.jpg') no-repeat center center / cover;
    overflow: hidden;
    z-index: 1;
}
  
  .main-title {
    position: absolute;
    margin: 0;
    padding: 0;
    color: #f9f1e9;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    text-transform: uppercase;
    font-size: 4.2em;
    letter-spacing: 0.1em;
  }
  
  .main-title .thin {
    font-weight: 200;
  }
  
  @media only screen and (max-width: 768px) {
    .main-title {
      font-size: 3em;
    }
  }
  