.container {
    position: relative;
    margin-left: 0;
    padding-left: 0;
    max-width: 10px;
}

.navbar {
    background-color: #263238;
    text-align: left;
    width: 100%;
    height:100%;
    position: sticky;
    padding: 0 !important;
    margin-left: 6px;
    margin-top: 2px;
    margin-right: 2px;
    border-radius: 10px;
}

.navbar-nav .nav-link {
    color: #f5f5f5 !important;
    padding: 1rem 1rem !important;
}

.navbar-nav .nav-item {
    position: relative;
    margin-right: 20px;
}

.navbar-nav .nav-item a:hover {
    background-color: #276678;
}

.toggle-icon {
    color: #f5f5f5 !important;
}

.navbar-toggler {
    padding: 0.5rem 1.5rem !important;
    position: relative !important;
    background-color: transparent !important;
    border: none transparent !important;
    outline: 0 !important;
    font-size: 2rem !important;
}
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

span {
    margin-left: 1rem;
}
