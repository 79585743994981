
.title {
    font-size: 15em;
    text-align: center;
}


.vertical-timeline-element-title {
    text-align: center;
    margin-bottom: 0.7rem;
    font-size: 30px !important;
    color: blueviolet;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
    background: #F6F6F6;
}


.vertical-timeline-element-description{
    margin-bottom: 1rem;
    font-size: 18px !important;
}

.vertical-timeline-element-icon {
    background: #263238;
    color: #fff;
}

.vertical-timeline-element-content-arrow {
    border-right: 7px solid  #F6F6F6;
    border-right: 7px solid  #F6F6F6;
}

.vertical-timeline-element-date {
    color: rgb(0, 0, 0);
    margin: 0 20px;
    font-weight:bolder !important;
    font-size: large;
}